import React, { useState, useEffect } from "react";
import UserListWithSearchBar from "./UserListWithSearchBar";
import UserOverView from "./UserOverView";
import UserRemoteList from "./UserRemoteList";
import AddRemoteToUserPanel from "./AddRemoteToUserPanel";
import ToolPanel from "../02_Tools/ToolPanel";
import { baseurl } from "../01_Helpers/endpoints";
const EditUser = (props) => {
  let [editMode, setEditMode] = useState(false);
  let [activeUser, setActiveUser] = useState(null);
  let [userRemoteList, setUserRemoteList] = useState([]);
  // eslint-disable-next-line no-unused-vars
  let [userListIsLoading, setUserListisLoading] = useState(false);
  let [rerenderCount, setRerenderCount] = useState(0);

  let [editMail, setEditMail] = useState("");
  let [editAccType, setEditAccType] = useState("");
  let [editRemoteList, setEditRemoteList] = useState([]);
  let [editRemoteScope, setEditRemoteScope] = useState([]);
  let [send, triggerSend] = useState(false);
  let [isSent, setIsSent] = useState(false);

  useEffect(() => {
    if (activeUser == null) return;
    setEditMail(activeUser.email);
    setEditAccType(activeUser.accType);
    if (userRemoteList == null) return;
    setEditRemoteList(userRemoteList);
    setEditRemoteScope(userRemoteList.map((item) => item.remoteID));
  }, [editMode, activeUser, userRemoteList]);

  useEffect(() => {
    if (!activeUser) return;
    setUserRemoteList(null);
    fetch(`${baseurl}/users/${activeUser.userID}`, {
      method: "GET",
      headers: {
        Authorization: JSON.parse(localStorage.getItem("token")),
        "Accept-Encoding": "gzip",
      },
    })
      .then((res) => res.json())
      .then((res) => setUserRemoteList(res.navbar.remotes))
      .catch((e) => setUserRemoteList([]));
  }, [activeUser]);

  useEffect(() => {
    if (isSent === false) return triggerSend(false);
    if (activeUser == null) return triggerSend(false);
    let userID = activeUser.userID;
    let remotes = editRemoteScope;
    let email = editMail;
    let accType = editAccType;

    if (email !== activeUser.email || accType !== activeUser.accType)
      fetch(`${baseurl}/users/${userID}`, {
        method: "PATCH",
        headers: {
          Authorization: JSON.parse(localStorage.getItem("token")),
          "Accept-Encoding": "gzip",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          tblUsers: {
            email: email,
            accType: accType,
          },
        }),
      }).then((res) => {
        if (res.status !== 200) throw new Error(res);
        return res.text();
      });

    let remoteListChanged =
      userRemoteList.length === remotes.length &&
      userRemoteList.reduce(
        (prev, rem) => prev && remotes.includes(rem.remoteID),
        true
      );
    if (!remoteListChanged)
      fetch(`${baseurl}/users/${userID}/UserRemote`, {
        method: "PUT",
        headers: {
          Authorization: JSON.parse(localStorage.getItem("token")),
          "Content-Type": "application/json",
          "Accept-Encoding": "gzip",
        },
        body: JSON.stringify({
          UserRemote: remotes.map((rem) => {
            return { remoteID: rem };
          }),
        }),
      }).then((res) => {
        if (res.status !== 200) return res.text();
        return res.text();
      });
    setIsSent(false);
    return triggerSend(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSent]);

  // Funktionen
  let deleteActiveUser = () => {
    if (!window.confirm("Soll der Benutzer gelöscht werden?")) return;
    fetch(`${baseurl}/users/${activeUser.userID}`, {
      method: "DELETE",
      headers: {
        Authorization: JSON.parse(localStorage.getItem("token")),
      },
    }).then((res) => {
      if (res.status !== 200)
        return alert(
          "Der Nutzer konnte nicht gelöscht werden, bitte versuchen Sie es erneut."
        );
      setActiveUser(null);
      setRerenderCount(rerenderCount + 1);
    });
  };

  return (
    <div className='card padding10 marginTop10 maxWidth700px'>
      <h3>Benutzer bearbeiten</h3>
      <div className='flexRow gap20'>
        <UserListWithSearchBar
          key={rerenderCount}
          userListIsLoading={userListIsLoading}
          activeUser={activeUser}
          setActiveUser={setActiveUser}
        />

        <div className='marginTop10 placeContentStart'>
          <UserOverView
            activeUser={activeUser}
            editMode={editMode}
            editMail={editMail}
            editAccType={editAccType}
            setEditMail={setEditMail}
            setEditAccType={setEditAccType}
          />
          {activeUser && (
            <UserRemoteList
              userRemoteList={editRemoteList}
              setUserRemoteList={setEditRemoteList}
              setEditRemoteScope={setEditRemoteScope}
              editRemoteScope={editRemoteScope}
              editMode={editMode}
            />
          )}
          {activeUser && editMode && (
            <AddRemoteToUserPanel
              editRemoteScope={editRemoteScope}
              setEditRemoteScope={setEditRemoteScope}
              send={send}
              isSent={setIsSent}
            />
          )}
          <span className='marginTop10'>
            <hr orientation='horizontal' />
          </span>
          {activeUser && (
            <ToolPanel
              editMode={editMode}
              setEditMode={setEditMode}
              discardChanges={() => {
                setEditMode(false);
              }}
              saveChanges={(mes) => {
                triggerSend(false);
                triggerSend(true);
              }}
              deleteItem={deleteActiveUser}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default EditUser;
