import React, { useEffect, useState } from "react";
import { v4 as uuid } from "uuid";

const RemoteOverview = (props) => {
  const {
    remoteList,
    remoteAndAnlagenIDs,
    activeRemote,
    editNickName,
    setEditNickName,
    editAnlagenID,
    setEditAnlagenID,
    editMode,
    patchBody,
    setPatchBody,
    serialNumber,
    serialNumbers,
    setSerialNumber,
    editSerialNumber,
    setEditSerialNumber,
  } = props;
  let [remoteName, setRemoteName] = useState("");
  let [anlagenID, setAnlagenID] = useState("");

  useEffect(() => {
    if (activeRemote == null) return;
    if (activeRemote.length === 0) return;

    let matchingRemote = remoteList.find(
      (item) => item.remoteID === activeRemote
    );
    let matchingAnlage = remoteAndAnlagenIDs.find(
      (item) => item.remoteID === activeRemote
    );
    let matchingSerial = serialNumbers.find(
      (item) => item.remoteID === activeRemote
    );
    setRemoteName(matchingRemote?.nickName);
    setAnlagenID(matchingAnlage?.anlagenID);
    setSerialNumber(
      matchingSerial?.serialNumber ? matchingSerial.serialNumber : ""
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeRemote]);

  useEffect(() => {
    setEditNickName(remoteName);
    setEditAnlagenID(anlagenID);
    setEditSerialNumber(serialNumber);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);

  useEffect(() => {
    if (remoteName === "") return;
    if (editNickName === "") return;
    let body = [...patchBody];
    if (remoteName === editNickName)
      return setPatchBody(
        body.filter((item) => item.table !== "remoteNickName")
      );

    let action = {
      id: uuid(),
      method: "MODIFY",
      database: "remoteDB",
      table: "remoteNickName",
      values: {
        nickName: editNickName,
      },
      selectors: {
        remoteID: activeRemote,
      },
    };
    let actionIndex = body.findIndex(
      (actionFromBody) => actionFromBody.table === action.table
    );

    if (actionIndex !== -1) body[actionIndex] = action;
    else body.push(action);
    return setPatchBody(body);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editNickName]);

  useEffect(() => {
    if (anlagenID === "") return;
    if (editAnlagenID === "") return;
    let body = [...patchBody];
    if (anlagenID === editAnlagenID)
      return setPatchBody(body.filter((item) => item.table !== "remoteAnlage"));
    let action = {
      id: uuid(),
      method: "MODIFY",
      database: "remoteDB",
      table: "remoteAnlage",
      values: {
        anlagenID: editAnlagenID,
      },
      selectors: {
        remoteID: activeRemote,
      },
    };
    let actionIndex = body.findIndex(
      (actionFromBody) => actionFromBody.table === action.table
    );
    if (actionIndex !== -1) body[actionIndex] = action;
    else body.push(action);
    return setPatchBody(body);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editAnlagenID]);

  useEffect(() => {
    //if (serialNumber === "") return;
    if (editSerialNumber === "") return;
    let body = [...patchBody];
    if (serialNumber === editSerialNumber)
      return setPatchBody(
        body.filter((item) => item.table !== "remoteSerialNumber")
      );
    let action =
      serialNumber === ""
        ? {
            id: uuid(),
            method: "ADD",
            database: "remoteDB",
            table: "remoteSerialNumber",
            values: {
              serialNumber: editSerialNumber,
            },
            selectors: {
              remoteID: activeRemote,
            },
          }
        : {
            id: uuid(),
            method: "MODIFY",
            database: "remoteDB",
            table: "remoteSerialNumber",
            values: {
              serialNumber: editSerialNumber,
            },
            selectors: {
              remoteID: activeRemote,
            },
          };
    let actionIndex = body.findIndex(
      (actionFromBody) => actionFromBody.table === action.table
    );
    if (actionIndex !== -1) body[actionIndex] = action;
    else body.push(action);
    return setPatchBody(body);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editSerialNumber]);

  if (activeRemote == null || activeRemote.length === 0) return <></>;

  return (
    <>
      <h5 className='textAlignStart '>Übersicht</h5>
      <div className='marginTop10 paddingLeft10 gridCol11ch1fr gap05 maxHeightMinContent'>
        <p className='gray-800  bold textAlignStart marginToBoAuto'>
          Remote-ID:
        </p>
        <p className='textAlignStart'>{activeRemote}</p>
        <p className='gray-800  bold textAlignStart marginToBoAuto'>
          Serien-Nr:
        </p>
        {editMode ? (
          <input
            type='text'
            value={editSerialNumber}
            onChange={(e) => setEditSerialNumber(e.target.value)}
          ></input>
        ) : (
          <p className='textAlignStart'>{serialNumber}</p>
        )}
        <p className='gray-800  bold textAlignStart marginToBoAuto'>Name:</p>
        {editMode ? (
          <input
            type='text'
            value={editNickName}
            onChange={(e) => setEditNickName(e.target.value)}
          ></input>
        ) : (
          <p className='textAlignStart'>{remoteName}</p>
        )}
        <p className='gray-800  bold textAlignStart marginToBoAuto'>
          Anlagen-ID:
        </p>
        {editMode ? (
          <input
            type='text'
            value={editAnlagenID}
            onChange={(e) => setEditAnlagenID(e.target.value)}
          ></input>
        ) : (
          <p className='textAlignStart'>{anlagenID}</p>
        )}
      </div>
    </>
  );
};

export default RemoteOverview;
