import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Routes, Route } from "react-router-dom";
import AnlagenDatenWidget from "./AnlagenDatenWidget";
import AnlagenKomponentenWidget from "./AnlagenKomponentenWidget";
import AnlagenLogbuchWidget from "./anlagenLogbuchWidget";
import AnlagenStatusWidget from "./AnlagenStatusWidget";
import "./AnlagenDash.css";
import AnlagenPowerUnitWidget from "./AnlagenPowerUnitWidget";
import AnlagenAbschaltungenWidget from "./AnlagenAbschaltungenWidget";
import AnlagenSchaltzeitenWidget from "./AnlagenSchaltzeitenWidget";
import AnlagenStoerungsWidget from "./AnlagenStoerungsWidget";
import Impedanzen from "./Impedanzen";
import Lautsprecherlinen from "./AnlagenLautsprecherLinien";
import Eingaenge from "./AnlagenEingaenge";
import Amps from "./AnlagenEndverstärkerWidget";
import { decodeBezeichnungen } from "../01_Helpers/anlagenBezeichnungenHelper";

const AnlagenDashBoard = (props) => {
  const [anlagenData, setAnlagenData] = useState(false);
  let { anlagenID } = useParams();
  const navigate = useNavigate();
  const [hideKomponenten, setHideKomponenten] = useState(false);
  const [hideAbschaltungen, setHideAbschaltungen] = useState(false);
  useEffect(() => {
    setHideAbschaltungen(false);
    setHideKomponenten(false);
    if (localStorage.getItem("token") == null) return navigate("/login");
    let scope = JSON.parse(localStorage.getItem("scope"));
    let anlagenScope = scope.anlagen.find(
      (item) => item.anlagenID === anlagenID
    );
    const controller = new AbortController();
    const signal = controller.signal;
    let getData = () =>
      fetch(anlagenScope.href, {
        signal: signal,
        method: "GET",
        headers: {
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
      }).then((data) => {
        data.json().then((jsonData) => {
          decodeBezeichnungen(jsonData.bezeichnungenVigilis.bezeichnungen);
          setAnlagenData(jsonData);
        });
      });
    getData();

    let refresh = setInterval(getData, 60000);

    return function cleanup() {
      controller.abort();
      clearInterval(refresh);
    };
  }, [anlagenID, navigate]);

  return (
    <Routes>
      <Route
        path=''
        element={
          <div className='anlagenDashBoard gap10 '>
            {anlagenData.statusVigilis?.status != null && (
              <AnlagenStatusWidget status={anlagenData.statusVigilis.status} />
            )}
            {anlagenData.statusVigilis?.status != null && (
              <AnlagenStoerungsWidget
                status={anlagenData.statusVigilis.status}
              />
            )}
            {anlagenData.datenVigilis?.daten != null && (
              <AnlagenDatenWidget daten={anlagenData.datenVigilis.daten} />
            )}
            {anlagenData.abschaltungVigilis?.abschaltung != null &&
              !hideAbschaltungen && (
                <AnlagenAbschaltungenWidget
                  status={anlagenData.statusVigilis.status}
                  abschaltungen={anlagenData.abschaltungVigilis.abschaltung}
                  setHideAbschaltungen={setHideAbschaltungen}
                />
              )}
          </div>
        }
      />
      <Route
        path='logbuch'
        element={
          <div className='anlagenDashBoard gap10 '>
            {anlagenData.logbuchVigilis != null && (
              <AnlagenLogbuchWidget
                logbuch={anlagenData.logbuchVigilis}
                limit={0}
              />
            )}
          </div>
        }
      />
      <Route
        path='stoerungen'
        element={
          <div className='anlagenDashBoard gap10 '>
            {anlagenData.statusVigilis?.status != null && (
              <AnlagenStoerungsWidget
                status={anlagenData.statusVigilis.status}
              />
            )}
            {anlagenData.komponentenVigilis?.komponenten != null &&
              !hideKomponenten && (
                <AnlagenKomponentenWidget
                  komponenten={anlagenData.komponentenVigilis.komponenten}
                  setHideKomponenten={setHideKomponenten}
                />
              )}
            {anlagenData.logbuchVigilis != null && (
              <AnlagenLogbuchWidget
                logbuch={anlagenData.logbuchVigilis}
                limit={8}
              />
            )}
          </div>
        }
      />
      <Route
        path='powerunit'
        element={
          <div className='anlagenDashBoard gap10 '>
            {anlagenData.powerunitVigilis?.powerunit != null && (
              <AnlagenPowerUnitWidget
                powerunit={anlagenData.powerunitVigilis.powerunit}
              />
            )}
          </div>
        }
      />
      <Route
        path='impedanzen'
        element={
          <div className='anlagenDashBoard gap10 '>
            {anlagenData.impedanz2Vigilis != null &&
            Array.isArray(anlagenData.impedanz2Vigilis) &&
            anlagenData.impedanz2Vigilis.some(
              (item) => item.linienIndex === "A"
            ) ? (
              <Impedanzen
                impedanzen={anlagenData.impedanz2Vigilis.filter(
                  (item) => item.linienIndex === "A"
                )}
                linienIndex='Kreis A'
              />
            ) : (
              <Impedanzen
                impedanzen={anlagenData.impedanz2Vigilis}
                linienIndex='Kreis A'
              />
            )}
            {Array.isArray(anlagenData.impedanz2Vigilis) &&
              anlagenData.impedanz2Vigilis.some(
                (item) => item.linienIndex === "B"
              ) && (
                <Impedanzen
                  impedanzen={anlagenData.impedanz2Vigilis.filter(
                    (item) => item.linienIndex === "B"
                  )}
                  linienIndex='Kreis B'
                />
              )}
          </div>
        }
      />
      <Route
        path='lslinien'
        element={
          <div className='anlagenDashBoard gap10 '>
            {anlagenData.kreisaVigilis != null && (
              <Lautsprecherlinen
                linienIndex='A'
                lsLinien={anlagenData.kreisaVigilis.kreisa}
              />
            )}
            {anlagenData.kreisbVigilis != null && (
              <Lautsprecherlinen
                linienIndex='B'
                lsLinien={anlagenData.kreisbVigilis.kreisb}
              />
            )}
          </div>
        }
      />
      <Route
        path='eingaenge'
        element={
          <div className='anlagenDashBoard gap10 '>
            {anlagenData.eingangVigilis != null && (
              <Eingaenge eingaenge={anlagenData.eingangVigilis.eingang} />
            )}
          </div>
        }
      />
      <Route
        path='amps'
        element={
          <div className='anlagenDashBoard gap10 '>
            {anlagenData.ampVigilis != null && (
              <Amps amps={anlagenData.ampVigilis.amp} />
            )}
          </div>
        }
      />
      <Route
        path='schaltzeiten'
        element={
          <div className='anlagenDashBoard gap10 '>
            {anlagenData && (
              <AnlagenSchaltzeitenWidget
                schaltzeiten={anlagenData.schaltzeitenVigilis.schaltzeiten}
              />
            )}
          </div>
        }
      />
    </Routes>
  );
};

export default AnlagenDashBoard;
/*
                        
            {anlagenData.powerunitVigilis?.powerunit != null && <AnlagenPowerUnitWidget powerunit={anlagenData.powerunitVigilis.powerunit}/>}
            {anlagenData.abschaltungVigilis?.abschaltung != null && !hideAbschaltungen && <AnlagenAbschaltungenWidget abschaltungen={anlagenData.abschaltungVigilis.abschaltung} setHideAbschaltungen={setHideAbschaltungen}/>}
            {anlagenData.komponentenVigilis?.komponenten != null && !hideKomponenten && <AnlagenKomponentenWidget komponenten={anlagenData.komponentenVigilis.komponenten} setHideKomponenten={setHideKomponenten}/>}
            {anlagenData.schaltzeitenVigilis?.schaltzeiten != null && <AnlagenSchaltzeitenWidget schaltzeiten={anlagenData.schaltzeitenVigilis.schaltzeiten}/>}
            
            {anlagenData && <AnlagenSchaltzeitenWidget schaltzeiten={anlagenData.schaltzeitenVigilis.schaltzeiten}/>}
            {anlagenData && <AnlagenMakrosWidget makros={anlagenData.makrosVigilis.makros}/>}
*/
