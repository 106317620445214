import React from "react";
import { useParams } from "react-router-dom";
import {
  decodeAnlagenStatus,
  decodeInfoByte,
} from "../01_Helpers/anlagenStatusHelper";
import "./AnlagenDash.css";
import { BsFillCircleFill, BsCircle } from "react-icons/bs";

function getIcon(entry, isTrue) {
  let okay = ["Betrieb", "Betriebsbereit"];
  let message = ["Abschaltung", "Störungen"];
  let alarm = ["Sprachalamierung"];
  if (okay.includes(entry))
    return isTrue ? (
      <BsFillCircleFill color={"green"} />
    ) : (
      <BsCircle color={"grey"} />
    );
  if (message.includes(entry))
    return isTrue ? (
      <BsFillCircleFill color={"orange"} />
    ) : (
      <BsCircle color={"grey"} />
    );
  if (alarm.includes(entry))
    return isTrue ? (
      <BsFillCircleFill color={"red"} />
    ) : (
      <BsCircle color={"grey"} />
    );
}

const AnlagenStatusWidget = (props) => {
  let status = props.status;
  let anlagenStatusDecoded = decodeAnlagenStatus(status);
  let anlagenStatusFiltered = anlagenStatusDecoded.filter(
    (item) => Object.values(item)[0] === true
  );
  let anlagenIcons = anlagenStatusDecoded.map((item) =>
    getIcon(Object.keys(item)[0], Object.values(item)[0])
  );
  let infoByte = decodeInfoByte(status);
  let { anlagenID } = useParams();
  return (
    <div className='anlagenWidget card padding10 margin10 gap10'>
      <div className='anlagenWidgetHeader'>
        <h3>Status</h3>
      </div>
      <div className='anlagenWidgetBody'>
        {anlagenStatusDecoded.map((item, index, array) => {
          let [key, value] = Object.entries(item)[0];
          return (
            <div className='StatusEntry marginTop05' key={index}>
              <span className='statusKey gray-800 bold'>{key}</span>
              <span className='statusValue textAlignCenter '>
                {anlagenIcons[index]}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AnlagenStatusWidget;

/*
let okay = ['Betrieb', 'Betriebsbereit']
    let message = ['Abschaltung', 'Störungen']
    let alarm = ['Sprachalamierung']
    if(okay.includes(entry)) return <BsFillCircleFill color={isTrue ? 'green' : 'gray'} style={{fontWeight:"bold"}}/>;
    if(message.includes(entry)) return <BsFillCircleFill color={isTrue ? "orange" : 'gray'}/>;
    if(alarm.includes(entry)) return <BsFillCircleFill color={isTrue ? "red" : 'gray'}/>;
    return <BsExclamationTriangle color="red"/>;
*/
