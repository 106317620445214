import React, { useEffect, useState } from "react";
import { baseurl } from "../01_Helpers/endpoints";
import { useForm } from "react-hook-form";

const RemoteUpdate = (props) => {
  const { register, handleSubmit } = useForm();
  let [allRemoteSelected, setAllRemotesSelected] = useState(false);
  let [remotesForUpdate, setRemoteForUpdate] = useState([]);
  let navbar = JSON.parse(localStorage.getItem("navbar"));
  let remotes = navbar.remotes;
  const sendUpdate = async (data) => {
    let formData = new FormData();
    formData.append("fileupload", data.file[0]);
    await fetch(`${baseurl}/admin/remoteUpdate`, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: JSON.parse(localStorage.getItem("token")),
      },
    }).then((res) => res.text());
  };
  useEffect(() => {
    if (remotesForUpdate.length !== remotes.length)
      return setAllRemotesSelected(false);
    let allRemotesIncluded = remotes
      .map((item) => remotesForUpdate.includes(item.remoteID))
      .reduce((prev, remoteInUpdate) => (remoteInUpdate &= prev), true);
    if (allRemotesIncluded) return setAllRemotesSelected(true);
    setAllRemotesSelected(false);
  }, [remotesForUpdate, remotes]);

  return (
    <div className='card padding10 marginTop10 maxWidth700px'>
      <h3>Update Hochladen</h3>
      <div className='gridCol2x1fr gap10'>
        <form
          className='marginTop10 placeContentStart'
          onSubmit={handleSubmit(sendUpdate)}
        >
          <h5 className='textAlignStart'>update.zip hochladen</h5>
          <input type='file' {...register("file")} />
          <button type='submit'>Update Hochladen</button>
        </form>
        <div>
          <ul className='marginTop10'>
            <h5 className='textAlignStart'>Remotes auswählen...</h5>
            <li
              className='textAlignStart marginTop10 marginBot05 pointer  textDecUnderLine'
              onClick={() =>
                allRemoteSelected
                  ? setRemoteForUpdate([])
                  : setRemoteForUpdate(remotes.map((item) => item.remoteID))
              }
            >
              {allRemoteSelected ? "Keins auswählen" : "Alle auswählen"}
            </li>
            {remotes.map((remote, index) => {
              return (
                <li
                  key={index}
                  className={`${
                    remotesForUpdate.includes(remote.remoteID)
                      ? "orange-600"
                      : "gray-600"
                  } textAlignStart marginLeft10 pointer resizeOnHover11`}
                  onClick={() => {
                    let akku = remotesForUpdate;
                    if (akku.includes(remote.remoteID))
                      return setRemoteForUpdate(
                        akku.filter((remID) => remID !== remote.remoteID)
                      );
                    return setRemoteForUpdate([
                      ...remotesForUpdate,
                      remote.remoteID,
                    ]);
                  }}
                >
                  {remote.name != null ? remote.name : remote.remoteID}
                </li>
              );
            })}
          </ul>
          <span className='marginTop10'>
            <hr orientation='horizontal'></hr>
          </span>
          <button>Update Starten</button>
        </div>
      </div>
    </div>
  );
};

export default RemoteUpdate;
