import React from "react";
import "./RemoteWidget.css";
import { HiOutlineCheckCircle } from "react-icons/hi";
import { BiErrorCircle } from "react-icons/bi";

function StatusAnlageWidget(props) {
  return (
    <div className='SubWidget card gridItemsCenter padding00 delay5'>
      <h4>Anlage</h4>
      <div className='content'>
        {props.status ? (
          <HiOutlineCheckCircle className='statusWidget check' color='green' />
        ) : (
          <BiErrorCircle
            className='statusWidget error'
            color='rgba(255, 0, 0, 0.8)'
          />
        )}
      </div>
    </div>
  );
}

export default StatusAnlageWidget;
