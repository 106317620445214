import React, { useState, useEffect } from "react";
import Searchbar from "../02_Tools/SearchBar";
import { FaTimes } from "react-icons/fa";
import { baseurl } from "../01_Helpers/endpoints";
/**
 * Props:
 * remoteScope
 * setRemoteScope
 */
const AddExistingRemoteToUser = (props) => {
  const { remoteScope, setRemoteScope } = props;

  let [remotesForAdding, setRemotesForAdding] = useState([]);
  let [selectedRemote, setSelectedRemote] = useState("");

  let [availableRemotes, setAvailableRemotes] = useState([]);

  useEffect(() => {
    fetch(`${baseurl}/modules?table=remoteNickName`, {
      method: "GET",
      headers: {
        Authorization: JSON.parse(localStorage.getItem("token")),
        "accept-encoding": "gzip",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setAvailableRemotes(res);
      })
      .catch((e) => {
        setAvailableRemotes([]);
      });
  }, []);

  return (
    <>
      <div className='maxWidth300px card padding10'>
        <h5>Bestehende Remotes</h5>
        <ul className='textAlignStart gray-800 margin10 fontSize08 bold'>
          {remotesForAdding.map((item, index) => (
            <li
              key={index}
              className='marginTop05 flexRow flexAlignCenter gap05'
            >
              <span>
                <FaTimes
                  className='red resizeWithOpacity fontSize12 pointer'
                  onClick={(e) => {
                    setRemotesForAdding(
                      remotesForAdding.filter(
                        (delItem) => delItem.remoteID !== item.remoteID
                      )
                    );
                    setRemoteScope(
                      remoteScope.filter(
                        (remoteID) => remoteID !== item.remoteID
                      )
                    );
                    setAvailableRemotes([...availableRemotes, item]);
                  }}
                ></FaTimes>
              </span>
              <span>
                <span className='orange-600'>{item.remoteID}</span> <br />
                {item.nickName}
              </span>
            </li>
          ))}
        </ul>
        <label>Auswahl</label>

        <select
          value={selectedRemote}
          onChange={(e) => setSelectedRemote(e.target.value)}
        >
          <option value='' disabled>
            Bitte wählen...
          </option>
          {availableRemotes.map((item, index) => (
            <option key={index} value={item.remoteID}>
              {item.nickName != null ? item.nickName : item.remoteID}
            </option>
          ))}
        </select>
        <Searchbar></Searchbar>
        <button
          onClick={(e) => {
            e.preventDefault();
            if (selectedRemote.length === 0 || selectedRemote == null)
              return alert("Bitte wählen Sie ein Gerät!");
            let remote = availableRemotes.find(
              (item) => item.remoteID === selectedRemote
            );
            setRemotesForAdding([...remotesForAdding, remote]);
            setAvailableRemotes(
              availableRemotes.filter(
                (item) => item.remoteID !== selectedRemote
              )
            );
            setRemoteScope([...remoteScope, selectedRemote]);

            setSelectedRemote("");
          }}
        >
          Hinzufügen
        </button>
      </div>
    </>
  );
};

export default AddExistingRemoteToUser;
