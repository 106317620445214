import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./AnlagenOverview.css";
import { BsFillCircleFill, BsCircle } from "react-icons/bs";
import {
  decodeAnlagenStatus,
  decodeAnlagenStatusAsObject,
} from "../01_Helpers/anlagenStatusHelper";
const AnlagenOverviewWidget = (props) => {
  const { anlagenContext } = props;
  const [anlagenStatus, setAnlagenStatus] = useState(null);
  useEffect(() => {
    const controller = new AbortController();
    const signal = controller.signal;
    let getData = () =>
      fetch(`${anlagenContext.updateLink}?table=statusVigilis`, {
        signal: signal,
        method: "GET",
        headers: {
          Authorization: JSON.parse(localStorage.getItem("token")),
        },
      })
        .then((res) => res.json())
        .then((res) => {
          let anlagenStatus = decodeAnlagenStatusAsObject(
            res.statusVigilis.status
          );
          setAnlagenStatus(anlagenStatus);
        })
        .catch((err) => console.log(err));
    getData();
    let interval = setInterval(getData, 60000);
    return () => {
      controller.abort();
      clearInterval(interval);
    };
  }, [anlagenContext]);

  return (
    <Link
      className='anlagenOverviewWidget card padding05 borderRadius10 orange-800 bold bgColorOrangeOnHover'
      to={anlagenContext.portalLink}
    >
      <span className='anlagenOverviewWidgetID anlagenOverviewWidgetTitle'>
        {anlagenContext.anlagenID}
      </span>
      <span className='anlagenOverviewWidgetName anlagenOverviewWidgetTitle bold'>
        {anlagenContext.anlagenName}
      </span>
      <span className='anlagenOverviewWidgetBetrieb anlagenOverviewWidgetSub gray-800'>
        {anlagenStatus?.Betrieb ? (
          <BsFillCircleFill color='green' />
        ) : (
          <BsCircle />
        )}
        <p>Betrieb</p>
      </span>
      <span className='anlagenOverviewWidgetBetriebsbereit anlagenOverviewWidgetSub gray-800'>
        {anlagenStatus?.Betriebsbereit ? (
          <BsFillCircleFill color='green' />
        ) : (
          <BsCircle />
        )}
        <p>Betriebsbereit</p>
      </span>
      <span className='anlagenOverviewWidgetStoerung anlagenOverviewWidgetSub gray-800'>
        {anlagenStatus?.Störungen ? (
          <BsFillCircleFill color='orange' />
        ) : (
          <BsCircle />
        )}
        <p>Störung</p>
      </span>
      <span className='anlagenOverviewWidgetAlarm anlagenOverviewWidgetSub gray-800'>
        {anlagenStatus?.Sprachalamierung ? (
          <BsFillCircleFill color='red' />
        ) : (
          <BsCircle />
        )}
        <p>Alarm</p>
      </span>
    </Link>
  );
};

export default AnlagenOverviewWidget;
