import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { FaPencilAlt, FaCheck, FaTimes } from "react-icons/fa";
import { BsToggleOff, BsToggleOn } from "react-icons/bs";
import { ScaleLoader } from "react-spinners";
import "./OutputSetting.css";

const generateActionDisplayString = (action, inputConfig) => {
  let part1 = "";
  let part2 = "";
  switch (Object.keys(action.condition)[0]) {
    case "temp":
      part1 = `${
        action.condition.temp.sensor === "tempInternal"
          ? "Temp intern"
          : "Temp extern"
      }`;
      if (action.condition.temp.isOver != null)
        part2 += ` > ${action.condition.temp.isOver}°C`;
      if (action.condition.temp.isUnder != null)
        part2 += ` < ${action.condition.temp.isUnder}°C`;
      break;
    case "input":
      let input = inputConfig.find(
        (item) => item.remoteInputIndex === action.condition.input.inputIndex
      );
      part1 = `${input.remoteInputName}`;
      part2 = ` - ${action.condition.input.state}`;
      break;
    case "date":
      let firstTime = new Date(action.condition.date.firstTime).toISOString();
      part1 = `${firstTime}`;
      part2 = `${action.condition.date.repeat}`;
      break;
    default:
      return "Error";
  }
  return `${part1} ${part2}`;
};

const getOutputState = async (remoteHref, token) => {
  return fetch(remoteHref, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
  })
    .then((res) => res.json())
    .catch((err) => err);
};

const setOutput = async (onoff, outputConfig, remoteHref, token) => {
  let output = outputConfig.remoteOutputIndex === 0 ? "output1" : "output2";
  let body = {
    setOutputRemote: {
      [output]: onoff,
    },
  };
  return fetch(remoteHref, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .catch((err) => err);
};

const patchOutputConfig = async (
  remoteHref,
  index,
  token,
  name,
  description,
  outputActions
) => {
  let body = {
    remoteOutputConfig: [
      {
        remoteOutputIndex: index,
        remoteOutputName: name,
        remoteOutputDesc: description,
        remoteOutputAction: outputActions,
      },
    ],
  };
  return fetch(remoteHref, {
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
    },
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .catch((err) => err);
};

const OutputSettingsWidget = (props) => {
  const {
    outputConfig,
    outputStatus,
    setOutputConfig,
    editMode,
    setEditMode,
    prevDesc,
    prevHead,
    setPrevHead,
    setPrevDesc,
    setOutputActions,
    inputConfig,
    oldActions,
    setOldActions,
    outputActions,
    setOutputIndex,
  } = props;

  const [outputOn, setOutputOn] = useState(false);
  const [actionPending, setActionPending] = useState(false);
  const [switchPending, setSwitchPending] = useState(false);
  const { remoteID } = useParams();

  const scope = JSON.parse(localStorage.getItem("scope"));
  const remoteHref = scope.remote.find(
    (item) => item.remoteID === remoteID
  ).href;
  const token = JSON.parse(localStorage.getItem("token"));

  let headRef = useRef(null);
  let descRef = useRef(null);

  let [actionsForIteration, setActionsForIteration] = useState([]);

  useEffect(() => {
    let outputBinString = outputStatus.toString(2);
    let outputActive =
      outputBinString[
        outputBinString.length - 1 - outputConfig.remoteOutputIndex
      ];
    setOutputOn(parseInt(outputActive) === 1 ? true : false);
    let setThisPlease =
      outputConfig.remoteOutputAction != null
        ? JSON.parse(outputConfig.remoteOutputAction)
        : [];

    if (editMode) {
      setActionsForIteration(outputActions);
    } else {
      setActionsForIteration(setThisPlease);
    }
  }, [editMode, props]);

  return (
    <div className='output-widget card alignItemsCenter padding10 gap05'>
      <span className='output-index'>{outputConfig.remoteOutputIndex + 1}</span>
      {editMode ? (
        <h3
          ref={headRef}
          contentEditable='true'
          className='editable height100 padding05'
          autoFocus
        >
          {outputConfig.remoteOutputName}
        </h3>
      ) : (
        <h3 className='height100 padding05' ref={headRef}>
          {outputConfig.remoteOutputName}
        </h3>
      )}
      {editMode ? (
        <p
          contentEditable='true'
          className='editable textAlignStart height100 padding05 gray-600'
          ref={descRef}
        >
          {outputConfig.remoteOutputDesc}
        </p>
      ) : (
        <p
          className='textAlignStart gray-600 height100 padding05'
          ref={descRef}
        >
          {outputConfig.remoteOutputDesc}
        </p>
      )}
      <hr />

      {actionPending ? (
        <div className='spinner settings placeSelfCenter iconLO'>
          <ScaleLoader size='10' width='2px' color='rgba(255, 166, 0, 0.479)' />
        </div>
      ) : editMode ? (
        <FaTimes
          className='placeSelfCenter resizeWithOpacity pointer abort iconUP'
          onClick={() => {
            headRef.current.textContent = prevHead;
            descRef.current.textContent = prevDesc;

            setEditMode(false);
            setActionsForIteration(oldActions);
            props.setAddOutputAction(null);
          }}
        />
      ) : (
        <FaPencilAlt
          className='placeSelfCenter resizeWithOpacity pointer settings iconLO'
          onClick={() => {
            setPrevHead(headRef.current.textContent);
            setPrevDesc(descRef.current.textContent);
            setOldActions(actionsForIteration);
            setOutputIndex(outputConfig.remoteOutputIndex + 1);
            setEditMode(true);
            props.setAddOutputAction(outputConfig.remoteOutputIndex);
            setOutputActions(actionsForIteration);
          }}
        />
      )}
      {switchPending ? (
        <div className='spinner output-switch placeSelfCenter pointer iconUP'>
          <ScaleLoader size='10' width='2px' color='rgba(255, 166, 0, 0.479)' />
        </div>
      ) : editMode ? (
        <FaCheck
          className='resizeWithOpacity placeSelfCenter pointer save iconLO'
          onClick={async () => {
            setActionPending(true);
            setEditMode(false);
            let res = await patchOutputConfig(
              remoteHref,
              outputConfig.remoteOutputIndex,
              token,
              headRef.current.textContent,
              descRef.current.textContent,
              JSON.stringify(outputActions)
            );
            if ("remoteOutputConfig" in (await res))
              setOutputConfig(res.remoteOutputConfig);
            setActionPending(false);
            props.setAddOutputAction(null);
          }}
        />
      ) : outputOn ? (
        <BsToggleOn
          className='resizeWithOpacity placeSelfCenter pointer output-switch on iconUP'
          onClick={async () => {
            setSwitchPending(true);
            await setOutput("OFF", outputConfig, remoteHref, token);
            let outChanged = await getOutputState(remoteHref, token);
            if (!Array.isArray(await outChanged.setOutputRemote)) {
              const fetchEvery10Seconds = setInterval(async () => {
                outChanged = await getOutputState(remoteHref, token);
                if (
                  Array.isArray(outChanged.setOutputRemote) &&
                  outChanged.setOutputRemote.length === 0
                ) {
                  clearInterval(fetchEvery10Seconds);
                  setSwitchPending(false);
                }
              }, 10000);
            }

            setOutputOn(!outputOn);
          }}
        />
      ) : (
        <BsToggleOff
          className='resizeWithOpacity placeSelfCenter pointer output-switch off iconUP'
          onClick={async () => {
            setSwitchPending(true);
            await setOutput("ON", outputConfig, remoteHref, token);
            let outChanged = await getOutputState(remoteHref, token);
            if (!Array.isArray(await outChanged.setOutputRemote)) {
              const fetchEvery10Seconds = setInterval(async () => {
                outChanged = await getOutputState(remoteHref, token);
                if (Array.isArray(await outChanged.setOutputRemote)) {
                  clearInterval(fetchEvery10Seconds);
                  setSwitchPending(false);
                }
              }, 10000);
            }
            setOutputOn(!outputOn);
          }}
        />
      )}

      <div className='output-actions padding05'>
        <h5 className='header-turnon'>Einschalten:</h5>
        <ul className='content-turnon'>
          {actionsForIteration
            .filter((item) => item.action === "setHigh")
            .map((item, index, array) => {
              let display = generateActionDisplayString(item, inputConfig);
              if (editMode)
                return (
                  <li key={index}>
                    <span className='list-item-with-delete-option'>
                      <FaTimes
                        className='resizeWithOpacity placeSelfCenter pointer red'
                        onClick={() => {
                          let leftElements = actionsForIteration.filter(
                            (actionItem) => actionItem.id !== item.id
                          );
                          setOutputActions(leftElements);
                        }}
                      />
                      <p>{display}</p>
                    </span>
                  </li>
                );
              else
                return (
                  <li>
                    <span className='list-item-with-delete-option'>
                      <p>{display} </p>
                    </span>
                  </li>
                );
            })}
        </ul>
        <h5 className='header-turnoff'>Ausschalten:</h5>
        <ul className='content-turnoff'>
          {actionsForIteration
            .filter((item) => item.action === "setLow")
            .map((item, index) => {
              let display = generateActionDisplayString(item, inputConfig);
              if (editMode)
                return (
                  <li>
                    <span className='list-item-with-delete-option'>
                      <FaTimes
                        className='resizeWithOpacity placeSelfCenter pointer red'
                        onClick={() => {
                          let leftElements = actionsForIteration.filter(
                            (actionItem) => actionItem.id !== item.id
                          );
                          setOutputActions(leftElements);
                        }}
                      />
                      <p>{display}</p>
                    </span>
                  </li>
                );
              else
                return (
                  <li>
                    <span className='list-item-with-delete-option'>
                      <p>{display}</p>
                    </span>
                  </li>
                );
            })}
        </ul>
      </div>
    </div>
  );
};

export default OutputSettingsWidget;
