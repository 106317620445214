import React, { useState, useEffect } from "react";
import { FaTimes } from "react-icons/fa";
import { baseurl } from "../01_Helpers/endpoints";
/**
 * Props:
 * remoteScope
 * setRemoteScope
 */

const generateRequestBody = (remoteID, nickName, anlagenID) => {
  let body = {};
  if (nickName != null) Object.assign(body, { remoteNickName: { nickName } });
  if (anlagenID != null) Object.assign(body, { remoteAnlage: { anlagenID } });
  let href = `${baseurl}/modules/${remoteID}`;
  return { href, body, remoteID };
};

const AddNewRemotesToUser = (props) => {
  let { remoteScope, setRemoteScope, send, isSent } = props; //['remoteIDs'] für userRemote - Table

  let [newRemoteName, setNewRemoteName] = useState("");
  let [newRemoteAnlagenID, setNewremoteAnlagenID] = useState("");
  let [selectedNewRemote, setSelectedNewremote] = useState("");
  let [newRemotes, setNewRemotes] = useState([]);
  let [newRemotesFromDB, setNewRemotesFromDB] = useState([]);
  let [requestsToSend, setRequestToSend] = useState([]);

  useEffect(() => {}, [send]);

  //1. Read Remote DB (newRemote)
  //2. Add remoteID's to option List
  useEffect(() => {
    fetch(`${baseurl}/modules?table=newRemote`, {
      method: "GET",
      headers: {
        Authorization: JSON.parse(localStorage.getItem("token")),
        "accept-encoding": "gzip",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        let remoteIDs = res.map((item) => item.remoteID);
        setNewRemotesFromDB(remoteIDs);
      })
      .catch((e) => {
        setNewRemotesFromDB([]);
      });
  }, []);

  useEffect(() => {
    isSent(false);
    if (send !== true) return;
    if (requestsToSend.length === 0) return isSent(true);
    let requestStuff = async () => {
      let allNewRemotesAdded = requestsToSend.map((request) => {
        return fetch(request.href, {
          method: "PATCH",
          headers: {
            Authorization: JSON.parse(localStorage.getItem("token")),
            "Content-Type": "application/json",
            "Accept-Encoding": "gzip",
          },
          body: JSON.stringify(request.body),
        });
      });
      let results = await Promise.all(allNewRemotesAdded).catch((err) => null);
      let states = results
        .map((item) => {
          if (item.status === 200) return true;
          return false;
        })
        .reduce((prev, item) => (prev ? item : prev), true);
      if (states) return isSent(true);
      return isSent(false);
    };
    requestStuff();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [send]);

  return (
    <>
      <div className='maxWidth300px card padding10'>
        <h5>Neue Remotes</h5>
        <ul className='textAlignStart gray-800 margin10 fontSize08 bold'>
          {newRemotes.map((item, index) => (
            <li
              key={index}
              className='marginTop05 flexRow flexAlignCenter gap05'
            >
              <span>
                <FaTimes
                  className='red resizeWithOpacity fontSize12 pointer'
                  onClick={(e) => {
                    setNewRemotes(
                      newRemotes.filter(
                        (delItem) => delItem.remoteID !== item.remoteID
                      )
                    );
                    setRemoteScope(
                      remoteScope.filter(
                        (remoteID) => remoteID !== item.remoteID
                      )
                    );
                    setNewRemotesFromDB([...newRemotesFromDB, item.remoteID]);
                  }}
                ></FaTimes>
              </span>
              <span>
                <span className='orange-600'>{item.remoteID}</span> <br /> Name:
                {" " + item.remoteName} <br></br> Anlage: {" " + item.anlagenID}
              </span>
            </li>
          ))}
        </ul>
        <label>Auswahl</label>
        <select
          value={selectedNewRemote}
          onChange={(e) => setSelectedNewremote(e.target.value)}
        >
          <option value='' disabled>
            Bitte wählen...
          </option>
          {newRemotesFromDB.map((item, index) => (
            <option key={index} value={item}>
              {item}
            </option>
          ))}
        </select>
        <div className='gridCol2x1fr marginTop10'>
          <label>Remote-Name</label>
          <input
            value={newRemoteName}
            onChange={(e) => setNewRemoteName(e.target.value)}
          ></input>
        </div>
        <div className='gridCol2x1fr marginTop10'>
          <label>AnlagenID</label>
          <input
            value={newRemoteAnlagenID}
            onChange={(e) => setNewremoteAnlagenID(e.target.value)}
          ></input>
        </div>
        <button
          onClick={(e) => {
            e.preventDefault();
            if (selectedNewRemote.length === 0)
              return alert("Bitte wählen Sie ein Gerät!");

            setNewRemotes([
              ...newRemotes,
              {
                remoteID: selectedNewRemote,
                remoteName: newRemoteName,
                anlagenID: newRemoteAnlagenID,
              },
            ]);
            setNewRemotesFromDB(
              newRemotesFromDB.filter((item) => item !== selectedNewRemote)
            );
            setRemoteScope([...remoteScope, selectedNewRemote]);
            let newRequest = generateRequestBody(
              selectedNewRemote,
              newRemoteName,
              newRemoteAnlagenID
            );
            setRequestToSend([...requestsToSend, newRequest]);
            setSelectedNewremote("");
            setNewRemoteName("");
            setNewremoteAnlagenID("");
          }}
        >
          Hinzufügen
        </button>
      </div>
    </>
  );
};

export default AddNewRemotesToUser;
